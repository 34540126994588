module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"partnerzy_fototransfer.pl","short_name":"partnerzy","start_url":"/","background_color":"#F29324","theme_color":"#F29324","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"pathname":"/"},"crumbLabel":"Strona główna ","crumbSeparator":" / "}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
