// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advertising-materials-js": () => import("./../src/pages/advertising-materials.js" /* webpackChunkName: "component---src-pages-advertising-materials-js" */),
  "component---src-pages-contract-js": () => import("./../src/pages/contract.js" /* webpackChunkName: "component---src-pages-contract-js" */),
  "component---src-pages-cooperation-rules-js": () => import("./../src/pages/cooperation-rules.js" /* webpackChunkName: "component---src-pages-cooperation-rules-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-promotion-js": () => import("./../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */)
}

